import api from "@/services/api-service";
import querystring from "querystring";

const url = "/pathogens";

const find = (query) => {
  return api().get(`${url}?${querystring.stringify(query)}`);
};

const save = (obj) => {
  return api().post(url, obj);
};

const update = (obj) => {
  return api().put(url, obj);
};

const findOne = (id) => {
  return api().get(`${url}/${id}`);
};

const findByName = (name) => {
  return api().get(`${url}/name/${name}`);
};

export { find, save, findOne, findByName, update };
